define("discourse/plugins/discourse-locations/discourse/components/location-label-container", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/will-destroy", "@ember/service", "discourse/components/d-button", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "discourse/plugins/discourse-locations/discourse/helpers/location-format", "discourse/plugins/discourse-locations/discourse/components/locations-map", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _didInsert, _willDestroy, _service, _dButton, _dIcon, _i18n, _locationFormat, _locationsMap, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LocationLableContainerComponent extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.inject]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "locationAttrs", [_tracking.tracked], function () {
      return [];
    }))();
    #locationAttrs = (() => (dt7948.i(this, "locationAttrs"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "geoAttrs", [_tracking.tracked], function () {
      return [];
    }))();
    #geoAttrs = (() => (dt7948.i(this, "geoAttrs"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "showMap", [_tracking.tracked], function () {
      return false;
    }))();
    #showMap = (() => (dt7948.i(this, "showMap"), void 0))();
    outsideClick = e => {
      if (!this.isDestroying && !(e.target.closest(".map-expand") || e.target.closest(".map-attribution") || e.target.closest(".location-topic-map") || e.target.closest("#locations-map"))) {
        this.showMap = false;
      }
    };
    get mapButtonLabel() {
      return `location.geo.${this.showMap ? "hide" : "show"}_map`;
    }
    get showMapButtonLabel() {
      return !this.site.mobileView;
    }
    get showMapToggle() {
      return this.args.topic.location.geo_location && this.siteSettings.location_topic_map;
    }
    get opts() {
      let opts = {};
      if (this.locationAttrs) {
        opts["attrs"] = this.locationAttrs;
      }
      if (this.geoAttrs) {
        opts["geoAttrs"] = this.geoAttrs;
      }
      return opts;
    }
    bindClick() {
      document.addEventListener("click", this.outsideClick);
    }
    static #_6 = (() => dt7948.n(this.prototype, "bindClick", [_object.action]))();
    unbindClick() {
      document.removeEventListener("click", this.outsideClick);
    }
    static #_7 = (() => dt7948.n(this.prototype, "unbindClick", [_object.action]))();
    toggleMap() {
      this.showMap = !this.showMap;
    }
    static #_8 = (() => dt7948.n(this.prototype, "toggleMap", [_object.action]))();
    static #_9 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          {{didInsert this.bindClick}}
          {{willDestroy this.unbindClick}}
          class="location-label-container"
        >
          <div class="location-label" title={{i18n "location.label.title"}}>
            {{icon "map-marker-alt"}}
            <span class="location-text">
              {{locationFormat @topic.location this.opts}}
            </span>
          </div>
    
          {{#if this.showMapToggle}}
            <div class="location-topic-map">
              <DButton
                @action={{this.toggleMap}}
                class="btn btn-small btn-default"
                @icon="far-map"
                @label={{if this.showMapButtonLabel this.mapButtonLabel}}
              />
            </div>
            {{#if this.showMap}}
              <div class="map-component map-container small">
                <LocationsMap @topic={{@topic}} @mapType="topic" />
              </div>
            {{/if}}
          {{/if}}
        </div>
      
    */
    {
      "id": "SIVfOT6s",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"location-label-container\"],[4,[32,0],[[30,0,[\"bindClick\"]]],null],[4,[32,1],[[30,0,[\"unbindClick\"]]],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"location-label\"],[15,\"title\",[28,[32,2],[\"location.label.title\"],null]],[12],[1,\"\\n        \"],[1,[28,[32,3],[\"map-marker-alt\"],null]],[1,\"\\n        \"],[10,1],[14,0,\"location-text\"],[12],[1,\"\\n          \"],[1,[28,[32,4],[[30,1,[\"location\"]],[30,0,[\"opts\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showMapToggle\"]],[[[1,\"        \"],[10,0],[14,0,\"location-topic-map\"],[12],[1,\"\\n          \"],[8,[32,5],[[24,0,\"btn btn-small btn-default\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"toggleMap\"]],\"far-map\",[52,[30,0,[\"showMapButtonLabel\"]],[30,0,[\"mapButtonLabel\"]]]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"],[41,[30,0,[\"showMap\"]],[[[1,\"          \"],[10,0],[14,0,\"map-component map-container small\"],[12],[1,\"\\n            \"],[8,[32,6],null,[[\"@topic\",\"@mapType\"],[[30,1],\"topic\"]],null],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@topic\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-locations/discourse/components/location-label-container.js",
      "scope": () => [_didInsert.default, _willDestroy.default, _i18n.default, _dIcon.default, _locationFormat.default, _dButton.default, _locationsMap.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = LocationLableContainerComponent;
});