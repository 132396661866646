define("discourse/plugins/discourse-locations/discourse/users-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("locations", function () {
      this.route("users-map", {
        path: "/users_map"
      });
    });
  }
});