define("discourse/plugins/discourse-locations/discourse/components/modal/add-location", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @closeModal={{@closeModal}}
    @flash={{this.flash}}
    class="add-location add-location-modal"
    @title={{this.title}}
  >
    <LocationForm
      @street={{this.street}}
      @neighbourhood={{this.neighbourhood}}
      @postalcode={{this.postalcode}}
      @city={{this.city}}
      @state={{this.state}}
      @countrycode={{this.countrycode}}
      @geoLocation={{this.geoLocation}}
      @rawLocation={{this.rawLocation}}
      @inputFields={{this.inputFields}}
      @searchOnInit={{this.searchOnInit}}
      @setGeoLocation={{this.setGeoLocation}}
      @searchError={{this.searchError}}
    />
    <hr />
    <div class="control-group">
      <label class="control-label">{{i18n "location.name.title"}}</label>
      <div class="controls">
        <Input
          @type="text"
          @value={{this.name}}
          class="input-xxlarge input-location location-name"
        />
      </div>
      <div class="instructions">{{i18n "location.name.desc"}}</div>
    </div>
    <div class="modal-footer">
      <DButton
        id="save-location"
        @action={{action "submit"}}
        @label="location.done"
        @class="btn-primary"
        @disabled={{this.submitDisabled}}
      />
      <DButton
        id="clear-location"
        @class="clear"
        @action={{action "clear"}}
        @label="location.clear"
      />
    </div>
  </DModal>
  */
  {
    "id": "+ssTdABt",
    "block": "[[[8,[39,0],[[24,0,\"add-location add-location-modal\"]],[[\"@closeModal\",\"@flash\",\"@title\"],[[30,1],[30,0,[\"flash\"]],[30,0,[\"title\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@street\",\"@neighbourhood\",\"@postalcode\",\"@city\",\"@state\",\"@countrycode\",\"@geoLocation\",\"@rawLocation\",\"@inputFields\",\"@searchOnInit\",\"@setGeoLocation\",\"@searchError\"],[[30,0,[\"street\"]],[30,0,[\"neighbourhood\"]],[30,0,[\"postalcode\"]],[30,0,[\"city\"]],[30,0,[\"state\"]],[30,0,[\"countrycode\"]],[30,0,[\"geoLocation\"]],[30,0,[\"rawLocation\"]],[30,0,[\"inputFields\"]],[30,0,[\"searchOnInit\"]],[30,0,[\"setGeoLocation\"]],[30,0,[\"searchError\"]]]],null],[1,\"\\n  \"],[10,\"hr\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,2],[\"location.name.title\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n      \"],[8,[39,3],[[24,0,\"input-xxlarge input-location location-name\"]],[[\"@type\",\"@value\"],[\"text\",[30,0,[\"name\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"instructions\"],[12],[1,[28,[35,2],[\"location.name.desc\"],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"modal-footer\"],[12],[1,\"\\n    \"],[8,[39,4],[[24,1,\"save-location\"]],[[\"@action\",\"@label\",\"@class\",\"@disabled\"],[[28,[37,5],[[30,0],\"submit\"],null],\"location.done\",\"btn-primary\",[30,0,[\"submitDisabled\"]]]],null],[1,\"\\n    \"],[8,[39,4],[[24,1,\"clear-location\"]],[[\"@class\",\"@action\",\"@label\"],[\"clear\",[28,[37,5],[[30,0],\"clear\"],null],\"location.clear\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"location-form\",\"i18n\",\"input\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-locations/discourse/components/modal/add-location.hbs",
    "isStrictMode": false
  });
  class AddLocationComponent extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "flash", [_tracking.tracked], function () {
      return this.args.model?.flash;
    }))();
    #flash = (() => (dt7948.i(this, "flash"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "searchOnInit", [_tracking.tracked], function () {
      return false;
    }))();
    #searchOnInit = (() => (dt7948.i(this, "searchOnInit"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "name", [_tracking.tracked], function () {
      return null;
    }))();
    #name = (() => (dt7948.i(this, "name"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "street", [_tracking.tracked], function () {
      return null;
    }))();
    #street = (() => (dt7948.i(this, "street"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "postalcode", [_tracking.tracked], function () {
      return null;
    }))();
    #postalcode = (() => (dt7948.i(this, "postalcode"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "city", [_tracking.tracked], function () {
      return null;
    }))();
    #city = (() => (dt7948.i(this, "city"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "countrycode", [_tracking.tracked], function () {
      return null;
    }))();
    #countrycode = (() => (dt7948.i(this, "countrycode"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "geoLocation", [_tracking.tracked], function () {
      return {
        lat: "",
        lon: ""
      };
    }))();
    #geoLocation = (() => (dt7948.i(this, "geoLocation"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "rawLocation", [_tracking.tracked], function () {
      return null;
    }))();
    #rawLocation = (() => (dt7948.i(this, "rawLocation"), void 0))();
    title = (() => _I18n.default.t("composer.location.title"))();
    constructor() {
      super(...arguments);
      const location = this.args.model.location;
      this.countrycode = this.siteSettings.location_country_default;
      if (location) {
        this.name = location.name;
        this.street = location.street;
        this.neighbourhood = location.neighbourhood;
        this.postalcode = location.postalcode;
        this.city = location.city;
        this.state = location.state;
        this.countrycode = location.countrycode;
        this.geoLocation = location.geo_location;
        this.rawLocation = location.raw;
      }
    }
    get inputFields() {
      return this.siteSettings.location_input_fields.split("|");
    }
    static #_11 = (() => dt7948.n(this.prototype, "inputFields", [(0, _object.computed)()]))();
    get submitDisabled() {
      return this.siteSettings.location_geocoding === "required" && !this.geoLocation;
    }
    static #_12 = (() => dt7948.n(this.prototype, "submitDisabled", [(0, _object.computed)("geoLocation")]))();
    clearModal() {
      this.name = null;
      this.street = null;
      this.neighbourhood = null;
      this.postalcode = null;
      this.city = null;
      this.state = null;
      this.countrycode = null;
      this.geoLocation = {
        lat: "",
        lon: ""
      };
      this.rawLocation = null;
    }
    static #_13 = (() => dt7948.n(this.prototype, "clearModal", [_object.action]))();
    clear() {
      this.clearModal();
      this.args.model.update(null);
      this.args.closeModal();
    }
    static #_14 = (() => dt7948.n(this.prototype, "clear", [_object.action]))();
    submit() {
      if (this.submitDisabled) {
        return;
      }
      let location = {};
      const geocodingEnabled = this.siteSettings.location_geocoding !== "none";
      const inputFields = this.inputFields;
      const hasCoordinates = inputFields.indexOf("coordinates") > -1;
      location["raw"] = this.rawLocation;
      const nonGeoProps = inputFields.filter(f => f !== "coordinates");
      nonGeoProps.forEach(f => {
        location[f] = this[f];
      });
      if (geocodingEnabled || hasCoordinates) {
        const geoLocation = this.geoLocation;
        if (geoLocation && geoLocation.lat && geoLocation.lon) {
          location["geo_location"] = geoLocation;
        }
      }
      let name = this.name;
      if (name) {
        location["name"] = name;
      }
      Object.keys(location).forEach(k => {
        if (location[k] == null || location[k] === "" || location[k] === {}) {
          delete location[k];
        }
      });
      if (Object.keys(location).length === 0) {
        location = null;
      }
      this.args.model.update(location);
      this.clearModal();
      this.args.closeModal();
    }
    static #_15 = (() => dt7948.n(this.prototype, "submit", [_object.action]))();
    setGeoLocation(gl) {
      this.name = gl.name;
      this.street = gl.street;
      this.neighbourhood = gl.neighbourhood;
      this.postalcode = gl.postalcode;
      this.city = gl.city;
      this.state = gl.state;
      this.geoLocation = {
        lat: gl.lat,
        lon: gl.lon
      };
      this.countrycode = gl.countrycode;
      this.rawLocation = gl.address;
    }
    static #_16 = (() => dt7948.n(this.prototype, "setGeoLocation", [_object.action]))();
    searchError(error) {
      this.flash = error;
    }
    static #_17 = (() => dt7948.n(this.prototype, "searchError", [_object.action]))();
  }
  _exports.default = AddLocationComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AddLocationComponent);
});