define("discourse/plugins/discourse-locations/discourse/components/geo-location-result", ["exports", "@ember/component", "@ember/helper", "@ember/modifier", "discourse/plugins/discourse-locations/discourse/helpers/geo-location-format", "@ember/template-factory"], function (_exports, _component, _helper, _modifier, _geoLocationFormat, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GeoLocationResultComponent extends _component.default {
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <li class="location-form-result {{if @location.selected 'selected'}}">
          <label {{on "click" (fn @updateGeoLocation @location false)}}>
            {{geoLocationFormat @location @geoAttrs}}
            {{#if this.showType}}
              {{#if @location.type}}
                <div class="location-type">/
                  {{@location.type}}
                </div>
              {{/if}}
            {{/if}}
          </label>
        </li>
      
    */
    {
      "id": "7AgHGei8",
      "block": "[[[1,\"\\n    \"],[10,\"li\"],[15,0,[29,[\"location-form-result \",[52,[30,1,[\"selected\"]],\"selected\"]]]],[12],[1,\"\\n      \"],[11,\"label\"],[4,[32,0],[\"click\",[28,[32,1],[[30,2],[30,1],false],null]],null],[12],[1,\"\\n        \"],[1,[28,[32,2],[[30,1],[30,3]],null]],[1,\"\\n\"],[41,[30,0,[\"showType\"]],[[[41,[30,1,[\"type\"]],[[[1,\"            \"],[10,0],[14,0,\"location-type\"],[12],[1,\"/\\n              \"],[1,[30,1,[\"type\"]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@location\",\"@updateGeoLocation\",\"@geoAttrs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-locations/discourse/components/geo-location-result.js",
      "scope": () => [_modifier.on, _helper.fn, _geoLocationFormat.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = GeoLocationResultComponent;
});