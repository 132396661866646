define("discourse/plugins/discourse-locations/discourse/helpers/location-format", ["exports", "@ember/template", "discourse/models/site", "discourse-common/lib/helpers", "discourse/plugins/discourse-locations/discourse/lib/location-utilities"], function (_exports, _template, _site, _helpers, _locationUtilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _locationFormat;
  (0, _helpers.registerRawHelper)("location-format", _locationFormat);
  function _locationFormat(location, opts) {
    let siteSettings = (0, _helpers.helperContext)().siteSettings;
    return (0, _template.htmlSafe)((0, _locationUtilities.locationFormat)(location, _site.default.currentProp("country_codes"), siteSettings.location_input_fields_enabled, siteSettings.location_input_fields, {
      ...opts
    }));
  }
});